<template>
  <div>
    <c-tab
      type="vertical"
      :tabItems.sync="tabItems"
      :inlineLabel="true"
      v-model="tab"
      :dense="true"
      align="left"
      :height="tabHeight"
      :splitter="splitter"
      tabClass="bg-blue-grey-1 text-black"
      tabClassVertical="leftTabAlign"
    >
      <template v-slot:default="tab">
        <component
          :is="tab.component"
          :key="tab.key"
          :popupParam="popupParam"
          :action="action"
          :result="tab.result"
        />
      </template>
    </c-tab>
  </div>
</template>

<script>
// import selectConfig from '@/js/selectConfig';
export default {
  name: 'internal-action-info',
  props: {
    popupParam: {
      type: Object,
      default: () => ({
        id: '',
      }),
    },
    action: {
      type: Object,
      default: () => ({
        id: '',  // 일련번호
        plantCd: '',
        col1: '', // 심사구분
        col2: '', // 심사 준비 요청사항
        col3: '', // 심사 목적 및 범위
        regDtStr: '',
        regUserName: '',
        auditMonthDepts: [], // 심사일정 - 피심사팀에 따른
        auditTeams: [], // 심사팀
        distributeTeams: [], // 배포부서
        checklistResult: [],
      }),
    },
    tabItems: {
      type: Array,
      default: () => {
        return [];
      },
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    contentHeight: null,
  },
  data() {
    return {
      splitter: { data: 200 },
      tab: 'tab1',
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    tabHeight() {
      return String(this.contentHeight - 60);
    },
  },
  watch: {
    tabItems: {
      handler: function () {
        if (this.tabItems && this.tabItems.length > 0) {
          this.$set(this.$data, 'tab', this.tabItems[0].name)
        }
      },
      deep: true,
    },
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      if (this.tabItems && this.tabItems.length > 0) {
        this.$set(this.$data, 'tab', this.tabItems[0].name)
      }
    },
  }
};
</script>